document.addEventListener('DOMContentLoaded', function (event) {
  // console.log('DOM fully loaded and parsed')
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.normalizeScroll(true)

  // Main Menu

  const menuAnchor = document.querySelector('.menubutton')
  const closeMenu = document.querySelector('.menuclose')
  const menuWrapper = document.querySelector('.mainMenu')

  const tl = gsap.timeline()

  menuAnchor.addEventListener('click', function () {
    document.body.classList.add('menuout')
    gsap.to(window, { duration: 0.1, scrollTo: 10 })
    if (!menuAnchor.classList.contains('is-active')) {
      this.classList.add('is-active')

      // tl.to(window, {
      //   scrollTo: 0,
      //   duration: 0.5,
      // })
    }
  })
  closeMenu.addEventListener('click', function () {
    document.body.classList.remove('menuout')

    if (menuAnchor.classList.contains('is-active')) {
      menuAnchor.classList.remove('is-active')

      // gsap.to(menuWrapper, {
      //   y: '-100%',
      //   duration: 0.5,
      // })
    }
  })

  const showAnim = gsap
    .from('header', {
      yPercent: -100,
      paused: true,
      duration: 0.2,
    })
    .progress(1)

  ScrollTrigger.create({
    start: '100 top',
    end: 99999,
    // markers: true,
    onUpdate: (self) => {
      self.direction === -1 ? showAnim.play() : showAnim.reverse()
    },
  })

  let trigger = gsap.utils.toArray('.zoomin')

  trigger.forEach((element) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        duration: 0.6,
      },
    })

    tl.from(element, {
      scale: 1.2,
      opacity: 0,
    })
  })

  let trigger2 = gsap.utils.toArray('.fadein')

  trigger2.forEach((element) => {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=100',
        duration: 1,
        // markers: true,
      },
    })

    tl2.from(element, {
      y: 50,
      opacity: 0,
    })
  })
})
